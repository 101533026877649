<template>
	<div class="loginBox">
		<div class="langBtn" @click="handleChangeLang">
			<div class="icon"><img src="@/assets/imgs/icon/login_qiehuan.png" alt="" /></div>
		</div>
		<!-- <div class="loginBg"><img src="@/assets/imgs/login/signin_bg.png" alt="" /></div> -->
		<div class="loginCon">
			<!-- <div class="icon"><img src="../../assets/imgs/icon/ico.jpg" alt="" /></div> -->
			<div class="icon">
				<img :src="logo_img" alt="" v-if="logo_img != ''"/>
				<p>{{ $t("tba.WelcometoRainbow") }}</p>
			</div>
			<div class="login">
				<div class="login_txt">
					<span class="l_txt">{{$t('login.login')}}</span>
					<div class="yy"></div>
				</div>
				<p class="loginTab">
					<span @click="setActive('password')" :class="{ mainColor1: activeTab === 'password' }">{{ $t('tba.Passwordlogin') }}</span>
					<span @click="setActive('code')" :class="{ mainColor1: activeTab === 'code' }">{{ $t('tba.SMSlogin') }}</span>
				</p>
				<components :is="regType"></components>
				<p class="tips">{{ $t("login.userLawB") }}<span class="mainColor1" @click="routerTo('/userLaw')">{{ $t("login.userLaw") }}</span></p>
			</div>
			<!-- <div class="zhuceBox">
				<span class="mainColor1" @click="routerToZhuCe()">{{ $t('login.RegisteredAccountNumber') }}</span>
			</div> -->
		</div>
	</div>
</template>

<script>
import LoginCode from '@/components/login/LoginCode.vue';
import LoginPwd from '@/components/login/LoginPwd.vue';

export default {
	name: 'login',
	components: {
		LoginCode,
		LoginPwd,
		// RegPhone
	},
	computed: {
		choiced() {
			if (this.langFalg == '中文') {
				return false;
			} else {
				return true;
			}
		}
	},
	data() {
		return {
			logo: require('@/assets/imgs/login/login.png'),
			regType: LoginPwd,
			activeTab: 'password',
			accessToken: this.$route.query.code,
			app: this.$route.query.app,
			columns: ['中文', 'Eng'],
			showPicker: false,
			langFalg: '中文',
			logo_img: ''
		};
	},
	created() {
		this.GetSystemImgList();
		let lang = localStorage.setItem('lang','zh');
		// console.log(this.$route.query.code)
		if (this.$route.query.code) {
			global.accessToken = this.accessToken;
			localStorage.accessToken = this.accessToken;

			// this.$dialog.alert({
			//   title: "提示",
			//   message: localStorage.accessToken + "accessToken"
			// });

			this.$post2('Article/user/api/passport/loginwithpwd', {})
				.then(res => {
					global.userInfo = res.user;
					global.defaultToken = res.user.defaultToken;

					localStorage.phone = res.user.phone;
					localStorage.openid = res.user.openid;
					localStorage.uid = res.user.uid;
					localStorage.app = res.user.app;

					localStorage.userInfo = JSON.stringify(res.user);
					if ((typeof localStorage.phone == 'undefined' || localStorage.phone == 0 || localStorage.phone == '') && localStorage.openid) {
						this.$router.push('/sPhone?bindPhone=1');
					} else {
						this.$router.push({
							path: 'start',
							query: {
								ifhave: false
							}
						});
					}
				})
				.catch(err => {
					alert(err);
				});
		}
	},
	methods: {
		routerTo(path) {
			this.$router.push(path)
		},
		handleChangeLang() {
			let lang = this.$i18n.locale
			// console.log('handleChangeLang', lang)
			if (lang === 'zh') {
				this.$i18n.locale = 'zhf'
				window.localStorage.setItem('lang', 'zhf')
			}
			if (lang === 'zhf') {
				this.$i18n.locale = 'en'
				window.localStorage.setItem('lang', 'en')
			}
			if (lang === 'en') {
				this.$i18n.locale = 'zh'
				window.localStorage.setItem('lang', 'zh')
			}
		},
		routerToFortePaw() {
			this.$router.push('/forgetThePassword');
		},
		//注册 页面
		routerToZhuCe() {
			this.$router.push('/registerPage');
		},

		onConfirm(data) {
			this.langFalg = data;
			if (this.langFalg === '中文') {
				this.$i18n.locale = 'zh';
			} else {
				this.$i18n.locale = 'en';
			}
			this.showPicker = false;
		},
		setActive(tab) {
			this.activeTab = tab;
			if (tab === 'code') {
				this.regType = LoginCode;
			} else if (tab == 'password') {
				this.regType = LoginPwd;
			}
		},
		//获取logo图
		GetSystemImgList() {
			this.$post2('User/Api/Passport/GetSystemImgList', {})
				.then(res => {
					this.logo_img = res.list[1].url;
				})
				.catch(e => {
					// this.$toast.fail(e);
				});
		}
	}
};
</script>
<style lang="less">
@import url('../../assets/css/login.less');
.loginBox {
	background-color: #FFFFFF;
	position: relative;
	.langBtn {
		z-index: 99;
		position: absolute;
		right: .2rem;
		top: 0;
		.icon {
			width: .6rem;
			height: .88rem;
			display: flex;
			justify-content: center;
			align-items: center;
			img {
				width: .4rem;
				height: .4rem;
			}
		}
	}
	.loginBg {
		position: absolute;
		z-index: 0;
		left: 0;
		right: 0;
		top: -.8rem;
		height: 100vh;
		overflow: hidden;
		img {
			width: 100%;
			display: block;
		}
	}
	.loginCon {
		position: relative;
		z-index: 1;
	}
	.zhuceBox {
		text-align: center;
		/* position: fixed; */
		/* left: 0; */
		width: 100%;
		/* bottom: 0.5rem; */
		margin-top: 0.5rem;
		padding-bottom: 0.5rem;
		span {
			font-size: 0.24rem;
			// font-family: PingFang SC;
			// font-weight: bold;
			color: #ff4b04;
		}
	}

	.loginCon .icon {
		// padding-top: 1.42rem;
		padding-top: 1.2rem;

		img {
			// width: 3.25rem;
			width: 2.4rem;
			height: 2.4rem;
			display: block;
			margin: auto;
		}
		p {
			color: white;
			font-size: .48rem;
			text-align: center;
		}
	}

	.login {
		// padding: 1rem 1rem 0 1rem;
		padding: .6rem .3rem 0 .3rem;
		
		.login_txt {
			position: relative;
			// padding-top: 0.3rem;
			.l_txt {
				font-size: 0.48rem;
				font-weight: 700;
			}
			.yy {
				position: absolute;
				left: 0;
				bottom: 0.05rem;
				z-index: -1;
				background-color: #8A8EFE;
				opacity: 0.2;
				height: 0.15rem;
				width: 1rem;
			}
		}

		.loginTab {
			// width: 4.6rem;
			
			height: .70rem;
			border-bottom: 1px solid #F5F5F5;
			margin: .8rem 0 .7rem 0;
			span {
				font-size: 0.32rem;
				// font-family: PingFang SC;
				// font-weight: bold;
				text-align: center;
				color: #666666;
				display: inline-block;
				width: 1.7rem;
				line-height: .7rem;
				margin: 0 .2rem;
			}

			span.on,
			span.mainColor1{
				color: #90C4FF;
				border-bottom: 1px solid #90C4FF;
			}
		}
		
		.tips {
			color: #999999;
			font-size: .28rem;
			text-align: center;
		}
	}
}
</style>
